/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

.body {
  display: flex;
  padding: 5rem 5rem 0 5rem;
  /* overflow-x: hidden; */
}

.body_main {
  /* overflow: hidden; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex: 1 1 50%;
}

.body_mainLeft {
  margin-right: auto;
}

.body_info {
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
}

.body_info:nth-child(2) {
  flex-wrap: wrap;
}

.body_headline > h1 {
  font-size: 4rem;
  font-family: "Merriweather", serif;
  color: white;
  margin-bottom: 3rem;
  width: 100%;
}

.body_infoGroups {
  display: flex;
}

.body_infoGroupSecond {
  margin-left: 1rem;
}

.body_infoGroupFirst {
  display: flex;
  flex-direction: column;
}

.body_infoGroupFirst > .body_infoGroups,
.body_infoGroupSecond > .body_infoGroups {
  margin-bottom: 100px;
}

.body_buttons {
  display: flex;
  margin-bottom: 1rem;
}

.body_buttons > .MuiButton-root:nth-child(2) {
  margin-left: 2rem;
  background-color: #104134;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
}

.body_buttons > .MuiButton-root:nth-child(1) {
  background-color: #fd2d7b;
  color: white;
  font-family: "Poppins", sans-serif;
}

.body_image {
  display: flex;
  min-width: 20vw;
}

.body_image > img {
  object-fit: contain;
  width: 100%;
  /* background-color: tomato; */
  margin-left: auto;
  /* position: relative;
  top: 5em; */
}

/* Media Query Tablet */
@media screen and (max-width: 768px) {
  .body {
    padding: 12px 0 0 0;
  }
  .body_mainLeft > * {
    margin-bottom: 50px;
  }
  .body_mainLeft {
    position: relative;
  }
  .body_headline > h1 {
    font-size: 2.7rem;
    line-height: 4.3rem;
    text-align: left;
  }
  .body_infoGroupSecond {
    margin: 0;
  }
  .body_main {
    display: grid;
    place-content: center;
    height: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .body_image {
    width: 25%;
    position: absolute;
    right: 12px;
    bottom: 0;
  }
}

/* Phones Media Queries */
@media screen and (max-width: 480px) {
  .body_headline > h1 {
    text-align: center;
  }
  .body_image {
    position: static;
    width: 40vw;
    margin: 0 0 36px 0;
    flex-shrink: 1;
  }
  .body_mainLeft {
    display: grid;
    place-items: center;
  }
  .body_headline {
    margin-bottom: 0;
  }
  .body_headline > h1 {
    font-size: 11vw;
  }
  .body_main {
    display: flex;
    flex-direction: column;
    place-items: center;
  }
  .body_info{
    justify-content: center;
  }
  .body_infoGroupSecond{
    margin-left: 32px;
  }
}

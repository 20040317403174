@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  background: rgb(1, 68, 52);
  background: linear-gradient(
    68deg,
    rgba(1, 68, 52, 1) 0%,
    rgba(0, 89, 68, 1) 35%,
    rgba(6, 84, 65, 1) 100%
  );
  
  
}
















.header {
  display: -webkit-flex;
  display: flex;
  max-height: 30rem;
  padding: 2rem 5rem;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-origin: border-box;
  overflow-x: hidden;
  width: 100vw;
}

.header_main{
  overflow-x: hidden;
}

.header_logo {
  display: -webkit-flex;
  display: flex;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-right: 5px;
}

.header_logo > .MuiSvgIcon-root {
  color: #fd2d7b;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 3.7rem;
  left: 9.5rem;
}

.header_logo > h3 {
  font-family: "Merriweather", serif;
  color: white;
}

.header_right {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.header_search {
  background-color: #104134;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 50px;
  padding: 3px;
  min-width: 30px;
}

.header_search > input {
  height: 20px;
  background: none;
  outline: none;
  border-style: none;
  color: rgb(216, 216, 216);
  padding: 5px;
}

.header_search > input::-webkit-input-placeholder {
  color: rgba(189, 189, 189, 0.6);
  font-weight: bold;
}

.header_search > input:-ms-input-placeholder {
  color: rgba(189, 189, 189, 0.6);
  font-weight: bold;
}

.header_search > input::placeholder {
  color: rgba(189, 189, 189, 0.6);
  font-weight: bold;
}

.header_search > .MuiSvgIcon-root {
  color: #fd2d7b;
  cursor: pointer;
}

.header_links {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
}

.header_links_name {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: auto;
  margin-bottom: auto;
}

.header_links_name > p {
  padding-left: 2rem;
  color: rgb(216, 216, 216);
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.header_links_name > p:hover{
    color: #fd2d7b;
    transition: all .1s ease-in-out 100ms;
}

.header_links > .MuiSvgIcon-root {
  color: #fd2d7b;
  margin-left: 2rem;
  cursor: pointer;
}

hr{
  background-color: #033519;
  border-width: 1.4px;
  border-color: rgb(0, 7, 3);
  margin-left: 10%;
  margin-right: 10%;
  opacity: 5%;
}

.header_links> .MuiIconButton-root{
  color: #fd2d7b;
  bottom: .1rem;
  margin-left: 1rem;;
}

.header_hr{
  width: 50%;
  min-height: 25px;
  border: none;
  display: -webkit-flex;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}




/* Media Query Tablet*/
@media screen and (max-width: 768px) {
    .header_logo > .MuiSvgIcon-root {
      position: relative;
      left: -1.3rem;
      top: 0.8rem;
    }
    .header {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      padding: 1rem 2rem 0 2rem;
      overflow-x: hidden;
    }
    .header_logo {
      margin-bottom: 2rem;
      margin-top: 1rem;
      margin-left: 24px;
      margin-right: 0;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
    }
    .header_right {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-around;
              justify-content: space-around;
      -webkit-flex-direction: column-reverse;
              flex-direction: column-reverse;
    }
    .header_search {
      margin-top: 1rem;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: flex-end;
              align-items: flex-end;
    }
    .header_links_name{
        width: 100%;
    }
    .header_links_name > p{
        padding-left: 1.8rem ;
        -webkit-justify-content: space-around;
                justify-content: space-around;
    }
    .header_links{
        display: -webkit-flex;
        display: flex;
        -webkit-align-content: center;
                align-content: center;
        -webkit-justify-content: center;
                justify-content: center;
    }
  }
/* Phone */
  @media screen and (max-width: 480px){
    .header{
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-align-content: center;
              align-content: center;
    }
    .header_right{
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      
    }
    .header_links_name{
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
    }
    .header_links{
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-shrink: 3;
              flex-shrink: 3;
    }
    .header_search{
      max-width: 35vw;
    }
    .header_search > input{
      max-width: 20vw;
    }
    .header_search > input::-webkit-input-placeholder{
      font-size: 4vw;
      -webkit-align-items: flex-start;
              align-items: flex-start;
    }
    .header_search > input:-ms-input-placeholder{
      font-size: 4vw;
      align-items: flex-start;
    }
    .header_search > input::placeholder{
      font-size: 4vw;
      -webkit-align-items: flex-start;
              align-items: flex-start;
    }
    .header_links_name > p{
      padding: 0 12px 0 0;
    }
    .header_links> .MuiIconButton-root{
      margin-left: 0;
    }
    .header_logo{
      margin-right: 0;
      margin-left: 24px;
    }
  }


/* Fonts */

.body {
  display: -webkit-flex;
  display: flex;
  padding: 5rem 5rem 0 5rem;
  /* overflow-x: hidden; */
}

.body_main {
  /* overflow: hidden; */
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex: 1 1 50%;
          flex: 1 1 50%;
}

.body_mainLeft {
  margin-right: auto;
}

.body_info {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
}

.body_info:nth-child(2) {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.body_headline > h1 {
  font-size: 4rem;
  font-family: "Merriweather", serif;
  color: white;
  margin-bottom: 3rem;
  width: 100%;
}

.body_infoGroups {
  display: -webkit-flex;
  display: flex;
}

.body_infoGroupSecond {
  margin-left: 1rem;
}

.body_infoGroupFirst {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.body_infoGroupFirst > .body_infoGroups,
.body_infoGroupSecond > .body_infoGroups {
  margin-bottom: 100px;
}

.body_buttons {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 1rem;
}

.body_buttons > .MuiButton-root:nth-child(2) {
  margin-left: 2rem;
  background-color: #104134;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
}

.body_buttons > .MuiButton-root:nth-child(1) {
  background-color: #fd2d7b;
  color: white;
  font-family: "Poppins", sans-serif;
}

.body_image {
  display: -webkit-flex;
  display: flex;
  min-width: 20vw;
}

.body_image > img {
  object-fit: contain;
  width: 100%;
  /* background-color: tomato; */
  margin-left: auto;
  /* position: relative;
  top: 5em; */
}

/* Media Query Tablet */
@media screen and (max-width: 768px) {
  .body {
    padding: 12px 0 0 0;
  }
  .body_mainLeft > * {
    margin-bottom: 50px;
  }
  .body_mainLeft {
    position: relative;
  }
  .body_headline > h1 {
    font-size: 2.7rem;
    line-height: 4.3rem;
    text-align: left;
  }
  .body_infoGroupSecond {
    margin: 0;
  }
  .body_main {
    display: grid;
    place-content: center;
    height: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .body_image {
    width: 25%;
    position: absolute;
    right: 12px;
    bottom: 0;
  }
}

/* Phones Media Queries */
@media screen and (max-width: 480px) {
  .body_headline > h1 {
    text-align: center;
  }
  .body_image {
    position: static;
    width: 40vw;
    margin: 0 0 36px 0;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
  }
  .body_mainLeft {
    display: grid;
    place-items: center;
  }
  .body_headline {
    margin-bottom: 0;
  }
  .body_headline > h1 {
    font-size: 11vw;
  }
  .body_main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    place-items: center;
  }
  .body_info{
    -webkit-justify-content: center;
            justify-content: center;
  }
  .body_infoGroupSecond{
    margin-left: 32px;
  }
}

.info{
    color:white;
    font-family: 'Poppins', sans-serif;
    font-size: .8rem;
}

.info_block{
    display: -webkit-flex;
    display: flex;
}

.info_block > p{
    margin-left: .8rem;
    margin-bottom: 1rem;
}















:root {
  --vpink: #fd2d7b;
  --vgreen: #104134;
  --font1: "Poppins", sans-serif;
  --font2: "Merriweather", serif;
}

.footer {
  background-color: white;
  display: -webkit-flex;
  display: flex;
  padding: 4rem 5rem 0 5rem;
  margin-bottom: 2rem;
  position: relative;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.footer_info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 28.6rem;
}

.footer_headline {
  font-size: 1.5em;
  margin-bottom: 1em;
  font-family: "Merriweather", serif;
  font-family: var(--font2);
  color: #104134;
  color: var(--vgreen);
}

.footer_description {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.footer_description > p {
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
  font-family: var(--font1);
  color: #104134;
  color: var(--vgreen);
}

.footer_description_readMore {
  color: #fd2d7b;
  color: var(--vpink);
  cursor: pointer;
}

.footer_contact {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  bottom: 0;
}

.footer_contact > p {
  color: #104134;
  color: var(--vgreen);
  font-family: "Poppins", sans-serif;
  font-family: var(--font1);
  font-weight: 600;
  font-size: 0.8em;
}

.footer_social {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.footer_social > .MuiSvgIcon-root {
  color: #fd2d7b;
  color: var(--vpink);
  cursor: pointer;
}
.footer_social > .MuiSvgIcon-root:first-child {
  margin-left: 10px;
}

/* Media Queries */

@media screen and (max-width: 768px) {
  .footer_contact {
    margin-top: 10px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;

    padding: 12px 12px 0 12px;
  }
  .footer_headline > h1 {
    font-size: 6vw;
  }
  .footer_description > p {
    font-size: 3.5vw;
  }
  .footer_social {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

.info{
    color:white;
    font-family: 'Poppins', sans-serif;
    font-size: .8rem;
}

.info_block{
    display: flex;
}

.info_block > p{
    margin-left: .8rem;
    margin-bottom: 1rem;
}















.app {
  background: rgb(1, 68, 52);
  background: linear-gradient(
    68deg,
    rgba(1, 68, 52, 1) 0%,
    rgba(0, 89, 68, 1) 35%,
    rgba(6, 84, 65, 1) 100%
  );
  
  
}















